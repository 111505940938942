const DynamicName = process.env.VUE_APP_COMPANY_NAME;
const Train_321 = {
    'SITE_NAME': 'Train 321',
    'SITE_URL': 'www.train321.com',
    'LOGO': 'Train_321.png',
    'INFO_EMAIL': 'info@train321.com',
    'SUPPORT_EMAIL': 'support@train321.com'
}
 const Home_of_Training = {
    'SITE_NAME': 'Home Of Training',
    'SITE_URL': 'www.homeoftraining.com',
    'LOGO': 'Homeoftraining.png',
    'INFO_EMAIL': 'info@homeoftraining.com',
    'SUPPORT_EMAIL': 'support@homeoftraining.com'
}
let Dynamic = {};
switch (DynamicName) { 
    case "Train_321":
        Dynamic[DynamicName] = Train_321;
        break;
    case "Home_of_Training":
        Dynamic[DynamicName] = Home_of_Training;
        break;
    default:
       Dynamic["Train_321"] = Train_321;  
}
let DName = Dynamic[DynamicName];
export  { DName as Dynamic }  ;
